
import { defineComponent, onMounted, reactive, toRaw } from 'vue'

import SCalendar from '@/common/components/SCalendar/index.vue'
import SelectedDateRange from '@/modules/HistoryNew/components/SelectedDateRange/index.vue'
import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SCheckbox from '@/common/components/SCheckbox/index.vue'

import iClose from '@/assets/icons/Close.svg'

import {
    SHIPMENT_TYPE_OPTIONS,
    PAYMENT_TYPE_OPTIONS,
    PAYMENT_METHOD_OPTIONS,
} from '@/core/constants/common.constants'

import {
    filtersCitiesState,
    useHistory,
    isLTLInvoices,
} from '@/modules/HistoryNew/composable/useHistory'

import { LTLHistoryFilters } from '@/core/classes/LTLHistoryFilters.class'
import { LTL_INVOICE_STATUSES } from '@/core/constants/LTL.constants'

export default defineComponent({
    components: {
        's-calendar': SCalendar,
        'selected-date-range': SelectedDateRange,
        's-input': SInput,
        's-button': SButton,
        'icon-close': iClose,
        SCheckbox,
    },
    emits: ['close'],
    setup(_, { emit }) {
        const { resetFilters, setLTLFilters, LTLFiltersState, loadData, loadCities } = useHistory()

        const state = reactive(new LTLHistoryFilters())

        onMounted(() => {
            loadCities()

            state.date_range = LTLFiltersState.date_range
            state.status = LTLFiltersState.status
            state.invoiceStatus = LTLFiltersState.invoiceStatus
            state.sender_city = LTLFiltersState.sender_city
            state.receiver_city = LTLFiltersState.receiver_city
            state.shipment_type = LTLFiltersState.shipment_type
            state.payment_type = LTLFiltersState.payment_type
            state.payment_method = LTLFiltersState.payment_method
            state.cod_payment = LTLFiltersState.cod_payment
            state.declared_price = LTLFiltersState.declared_price
            state.sender = LTLFiltersState.sender
            state.receiver = LTLFiltersState.receiver
            state.archive = LTLFiltersState.archive
        })

        const emitClose = () => {
            emit('close')
        }

        const resetFiltersHandler = () => {
            state.date_range = {
                date_from: null,
                date_to: null,
            }
            state.status = null
            state.invoiceStatus = null
            state.sender_city = null
            state.receiver_city = null
            state.shipment_type = null
            state.payment_type = null
            state.payment_method = null
            state.cod_payment = null
            state.declared_price = null
            state.sender = null
            state.receiver = null
            state.archive = false

            resetFilters()
            emitClose()
            loadData()
        }

        const applyFilters = () => {
            emitClose()
            setLTLFilters(toRaw(state))
            loadData()
        }

        const onRangeChange = () => {
            state.archive = true
        }

        return {
            isLTLInvoices,
            LTL_INVOICE_STATUSES,
            SHIPMENT_TYPE_OPTIONS,
            PAYMENT_TYPE_OPTIONS,
            PAYMENT_METHOD_OPTIONS,
            state,
            emitClose,
            resetFiltersHandler,
            applyFilters,
            onRangeChange,
            filtersCitiesState,
        }
    },
})
